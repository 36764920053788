import './App.css';
import Header from './Header';
import Body from './Body';

function App() {
  return (
    <div className="App">

      <Header />

      <Body />


    </div>
  );
}

export default App;
