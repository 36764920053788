import Projects from './Projects';

function Body() {

    return (
        <div>
            <p>
                Software Engineer with a background collaboratively communicating in the 3D Print Industry.
            </p>
            <p>
                Life long learner currently working with React, Redux, Node, and SQL.
            </p>


            <div>
                <h2>Projects</h2>

            <Projects />

            </div>

        </div>
    )
}

export default Body;